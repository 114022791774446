import * as React from "react"
import { SEO } from "../../components/seo"
import Layout from '../../components/vi/layout';
import { Link } from "gatsby";

const NotFound = (props) => {
  return (
    <Layout pathname={props.location.pathname}>
      <section className="h-72 bg-gradient-to-tl from-primary-dark to-primary-light relative after:content-[''] after:block after:bg-mask after:bg-['100%'] after:bg-bottom after:bg-no-repeat after:absolute after:inset-x-0 after:-bottom-1 after:w-full after:h-36 after:z-10">
        <div className="absolute w-full text-center inset-x-0 top-1/2 -translate-y-1/2 z-10 lg:p-20">
          <h1 className="animate__animated animate__fadeInDown animate__slower text-5xl text-white mb-4">Page Not Found</h1>
        </div>
      </section>
      <section className="container mt-14 mx-auto px-4 lg:px-36 text-center">
       
        <p className="font-bold text-xl mb-10">Error 404. Page not found.</p>

        <Link to="/" className="animate__animated animate__fadeInLeft animate__delay-1s font-bold text-[#3D3D3D] bg-gradient-to-r from-secondary via-secondary-light to-secondary text-base lg:text-xl rounded-full py-2 px-12 text-center transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100">
            Home
            <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="23.616" viewBox="0 0 13.503 23.616" role="img" aria-hidden="true" className="inline-block fill-[#3D3D3D] w-1.5 ml-2 align-middle -mt-1">
              <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z" transform="translate(-11.246 -6.196)"/>
            </svg>
            </Link>

      </section>
    </Layout>
  )
}

export default NotFound

export const Head = ({ location }) => <SEO title="404 - Page Not Found" pathname={location.pathname} />
