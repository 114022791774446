import * as React from "react"
import Header from './header';
import Footer from './footer';
import { Helmet } from 'react-helmet'

export default function Layout({ children, pathname }) {
  return (
    <div id="site-wrapper" className="min-h-screen">
      <Helmet htmlAttributes={{ lang: 'vi' }} />
      <Header pathname={pathname}/>
      <main>
        {children}
      </main>
      <Footer />
    </div>
  )
}